import {Authenticator} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';

import {Amplify} from "aws-amplify";
import {outputs} from "./auth/amplify_outputs";
import Main from "./main/Main";

Amplify.configure(outputs)

export default function App() {
    console.log("startApp")
    return (
        <Authenticator hideSignUp={true}
                       components={{
                           SignIn: {
                               Footer: () => null
                           },
                       }}
        >
            {({signOut, user}) => (
                <Main signOut={signOut} user={user}/>
            )}
        </Authenticator>
    );
}
