import {Button, Card, Flex, Heading, SelectField} from "@aws-amplify/ui-react";
import {AuthUser,fetchAuthSession} from "aws-amplify/auth";
import {UseAuthenticator} from "@aws-amplify/ui-react-core";
import {useEffect, useState} from "react";
import {AxiosHeaders} from "axios";
import {siteApi} from "../service/hecone/hecone";
import {CompGetSitesResponse} from "../service/hecone/gen";

type SignOut = UseAuthenticator['signOut'];

interface Probs {
    signOut: SignOut | undefined
    user: AuthUser | undefined
}

function Main({signOut, user}: Probs) {

    const [siteList, setSiteList] = useState<CompGetSitesResponse[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const session = await fetchAuthSession()

            const headers = new AxiosHeaders()
            const token = session.tokens?.accessToken.toString()
            if (token === undefined) {
                return
            }
            headers.setAuthorization(token)

            const response = await siteApi.getSites(undefined, undefined, undefined, undefined, undefined, {
                validateStatus: () => true,
                headers: headers
            })


            setSiteList(response.data?.data ?? []);
        }

        fetchData().catch((e)=>  console.error(e))
    }, []);

    return (
        <Card>
            <Flex direction="column" alignItems="flex-start">
                <Heading level={1}>Logged in with device {user?.username}</Heading>
                <Button variation="primary" onClick={signOut}>Sign out</Button>
                <SelectField
                    label="Site"
                    descriptiveText="On Which Site do the device stand"
                >

                    {siteList.map((site: CompGetSitesResponse) => (<option key={site.id} value={site.id}>{site.name}</option>))}
                </SelectField>
                <p>{JSON.stringify(siteList)}</p>
            </Flex>
        </Card>
    )
}

export default Main;